<template>
  <div class="chat">
       <el-row :gutter="20">
      <el-col :span="8">
        <el-input placeholder="请输入内容" clearable>
          <el-button slot="append" icon="el-icon-search"></el-button>
        </el-input>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
    name:'chat'
}
</script>

<style lang='less'>
.chat{

}
</style>